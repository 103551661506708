<template>
  <div class="user-page container-fluid h-100">

    <div class="d-flex flex-column justify-content-between h-100">
      <div>

        <div class="h-top">
          <router-link :to="{path: '/user/login'}" class="button-back-empty">
            <span class="fa fa-arrow-left"></span>
          </router-link>
        </div>

        <div class="h-title">
          RESTORE PASSWORD
        </div>

        <form class="col-md-4 offset-md-4 h-form" @submit.prevent="doUserRecover">

          <div class="form-group mb-3">
            <input
                v-model="email"
                :class="{'is-invalid': errors?.email}"
                type="text"
                class="form-control rounded-4"
                id="email-input"
                placeholder="Email" />
            <div class="invalid-feedback">{{ errors?.email }}</div>
          </div>

          <button class="btn btn-form mt-2" type="submit">Recover</button>

        </form>

      </div>
      <div class="h-bottom">
        NEED A NEW ACCOUNT?
        <router-link to="/user/register">Sign up</router-link>
      </div>
    </div>

  </div>
</template>

<script>

export default {

  data: () => ({
    email: null
  }),

  mounted() {

    if (this.isUser())
      return this.redirect('/');
  },

  methods: {

    async doUserRecover() {

      let apiHolder = await this.sendApiPost('api/user/recover', {
        email: this.email
      });

      if (!apiHolder.isSuccess())
        return;

      await this.redirect('/user/login', 'Instructions for restoring your account have been sent to your email address',
          'success');
    }

  },

}
</script>

<style scoped>

.h-title {
  padding-top: 80px;
}

</style>